.stepper-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: $customerBrand;
    height: 100vh;
    border-radius: 0 30px 30px 0;
    padding: 4rem 0 0 3rem; }

@media screen and ( max-width: 1367px ) {
    .stepper-container {
        padding: 3rem 0 0 3rem;
        border-radius: 0 20px 20px 0; } }

@media screen and ( max-width: 1281px ) {
    .stepper-container {
        padding: 2.5rem 0 0 2.5rem; } }

@media screen and ( max-width: 1024px ) {
    .stepper-container {
        padding: 2.5rem 0 0 2.5rem;
        border-radius: 0 10px 10px 0; } }

@media screen and ( max-width: 768px ) {
    .stepper-container {
        padding: 2rem 0 0 1.5rem; } }
