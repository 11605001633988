#panel-moreInfo-modal {
    .modal-content {
        border-radius: 20px;
        .modal-header {
            padding: 2.5rem 2.5rem 1.5rem;
            .modal-title {
                width: 100%;
                text-align: center;
                font-weight: $bold; } }
        .modal-body,
        .modal-footer {
            width: 90%;
            margin: 0 auto;
            padding: 0; }
        .modal-body {
            padding: 0 2rem;
            .accordionHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .accordion-btn {
                    border: none;
                    background: none;
                    color: $customerBrand; } }
            #abstract {
                padding-bottom: 1rem;
                border-bottom: 1px solid $grayMisc;
                .product-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    flex-wrap: nowrap;
                    width: fit-content;
                    .product-item {
                        background-color: $backgroundLight;
                        border-radius: 8px;
                        box-shadow: 2px 2px 8px 0px #0000001A;
                        width: fit-content;
                        padding: 1rem;
                        text-align: left;
                        .product-name {
                            color: $customerBrand;
                            &.modal-subtitle {
                                font-size: 1.3rem; } }
                        p {
                            margin: 0; } } }
                .price-wrapper {
                    padding: 1.5rem 0 0;
                    .price-list {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        .price-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0.5rem 0 0 0;
                            color: $grayMisc;
                            font-weight: $bold;
                            &.total {
                                color: $blackText;
                                font-size: 1.2rem; }
                            &.package-size {
                                font-weight: $regular;
                                color: $blackText; }
                            p {
                                margin: 0; } } } } }
            #clientInfo {
                padding: 1rem 0;
                border-bottom: 1px solid $grayMisc;
                .clientInfo-inputs-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-content: center;
                    .inputs-grid-wrapper {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        column-gap: 1rem;
                        .invalid-msg {
                            margin-top: 0.3rem;
                            margin-bottom: 0.7rem;
                            font-size: 0.8rem; } } } }
            #deliveryInfo {
                .delivery-list {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    .delivery-item {
                        font-weight: $bold;
                        padding: 0.3rem 0 0 0;
                        .content {
                            font-weight: $regular;
                            &.highlight {
                                color: $invalidText; }
                            &.gray {
                                color: gray; } } } } } }
        .modal-footer {
            padding: 1rem 0 3rem; } } }

@media screen and ( max-width: 1366px ) {
    #panel-moreInfo-modal {
        .modal-content {
            height: 550px;
            .modal-body {
                padding: 0 2rem;
                #abstract {
                    .product-list {
                        .product-item {
                            padding: 1rem 1.3rem;
                            .product-name {
                                &.modal-subtitle {
                                    font-size: 1.1rem;
                                    margin: 0 0 0.2rem 0; } }
                            p {
                                margin: 0; } } }
                    .price-wrapper {
                        padding: 0.5rem 0 0;
                        .price-list {
                            .price-item {
                                &.total {
                                    font-size: 1.1rem; } } } } } }
            .modal-footer {
                padding: 1rem 0 2rem; } } } }

@media screen and ( max-width: 1280px ) {
    #panel-moreInfo-modal {
        .modal-content {
            height: 450px; } } }
