* {
    &::-webkit-scrollbar {
        width: 0.5rem; } }

* {
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(173, 173, 214, 0.5);
        background-color: #F5F5F5;
        border-radius: 10px; } }

* {
    &::-webkit-scrollbar-thumb {
        background-color: $navItems;
        border-radius: 10px; } }
