#labels-container {
  min-height: 100dvh;
  background: $customerBrand;
  display: flex;
  align-items: center;
  gap: 4rem;
  flex-flow: column;
  padding-top: 4rem;

  header {
    img {
      max-width: 350px; } }

  section {
    width: 75vw;
    max-width: 800px;
    min-height: 50vh;
    padding: 1rem;
    background: #fff;
    border-radius: 23px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .messageWrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 2rem;
      margin: 2rem;

      header {
        display: flex;
        gap: 1rem;
        align-items: center;
        text-align: center;

        h2 {
          margin: 0;
          font-size: 2.25rem;
          font-weight: 700;
          transition: font-size 200ms;

          @media screen and ( max-width: 1024px ) {
            font-size: 1.75rem; } } }

      .print-labels-img {
        width: 100%;
        max-width: 270px; } } } }
