@mixin btnCustomStyle($mainColor, $hovered, $disabled, $active) {
    font-size: 1.125rem;
    font-weight: $bold;
    padding: 1rem 2.5rem;
    background-color: $mainColor;
    border: 1px solid $mainColor;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
    &:disabled {
        opacity: 1;
        background-color: $disabled;
        border: 1px solid $disabled; }
    &:hover {
        background-color: $hovered;
        border: 1px solid $hovered; }
    &:active {
        background-color: $active;
        border: 1px solid $active; }
    &:focus {
        background-color: $active; }
    @media screen and ( max-width: 1367px ) {
        font-size: 1rem;
        padding: 0.7rem 2rem; }
    @media screen and ( max-width: 1025px ) {
        font-size: 0.75rem;
        padding: 0.7rem 1.5rem; }
    @media screen and ( max-width: 768px ) {
        font-size: 0.6rem;
        padding: 0.5rem 1rem; } }

.btn-primary,
.btn-secondary,
.btn-outline-primary,
.btn-outline-secondary {
    @include btnCustomStyle($customerBrand, $customerBrand-hovered, $customerBrand-disabled, $customerBrand-active);
    &.block {
        padding: 1.5rem;
        font-size: 1.125rem;
        font-weight: $bold;
        width: 100%; }
    &.grid-fixed-size {
        padding: 0.5rem 1rem;
        margin: 0 auto;
        font-weight: $bold;
        font-size: 0.8rem;
        border-radius: 6px;
        &.full {
            background-color: $customerBrand;
            color: $lightText;
            border: 1px solid $customerBrand;
            &:hover {
                background-color: $customerBrand-hovered; } }
        &.outlined {
            background-color: transparent;
            color: $customerBrand;
            border: 1px solid $customerBrand;
            &:hover {
                background-color: $backgroundLight; } } }
    &#dropdown-basic-button {
        background: none;
        border: none;
        color: $blackText;
        padding: 0;
        font-weight: $bold;
        font-size: 0.75rem; } }

.close-btn {
    padding: 0;
    border: none;
    border-radius: 50px;
    background-color: $customerBrand-secondary;
    color: $lightText;
    width: 25px;
    height: 25px;
    font-size: 12px;
    font-weight: $medium;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 0.5rem;
    vertical-align: sub; }

.info-btn {
    background: none;
    border: none; }

.btn-secondary {
    background-color: $customerBrand-secondary;
    color: $lightText; }

.btn-outline-primary {
    background-color: transparent;
    color: $customerBrand;
    font-weight: $bold;
    border: 1px solid $customerBrand;
    &:hover {
        border: 1px solid $customerBrand;
        color: $customerBrand;
        background-color: $backgroundLight; }
    &.selected {
        background-color: $customerBrand; } }

.btn-outline-secondary {
    background-color: transparent;
    color: $customerBrand;
    font-weight: $bold;
    border: 1px solid $customerBrand;
    &:hover {
        border: 1px solid $customerBrand;
        color: $customerBrand;
        background-color: $backgroundPanel; } }

.btn-link {
    border: none;
    background: none;
    color: $customerBrand !important;
    text-decoration: none;
    a {
        color: $customerBrand; }
    &:hover {
        color: $customerBrand-hovered; }
    &.tracking-grid {
        color: $blackText;
        text-decoration: underline;
        font-weight: $medium; } }

.btn-outline-custom {
    color: $navItems;
    border-radius: 5px;
    font-size: 0.75rem; }

.btn-check:active+.btn-outline-custom,
.btn-check:checked+.btn-outline-custom,
.btn-outline-custom.active,
.btn-outline-custom.dropdown-toggle.show,
.btn-outline-custom:active {
    color: white;
    background-color: $customerBrand; }

@media screen and ( max-width: 1367px ) {
    .btn-primary,
    .btn-outline-primary {
        &.block {
            padding: 1rem;
            font-size: 1rem; }
        &.small {
            padding: 0.3rem 2.5rem;
            font-size: 0.75rem; }
        &.grid-fixed-size {
            padding: 0.3rem 1.3rem; } } }

@media screen and ( max-width: 1025px ) {
    .btn-primary,
    .btn-outline-primary {
        &.block {
            padding: 0.7rem;
            font-size: 0.875rem; }
        &.grid-fixed-size {
            padding: 0.3rem 1rem;
            margin: 0 0.2rem; } }
    .btn-link {
        font-size: 0.75rem; } }
