#fulltable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding: 0;
    .topBar-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0.5rem !important;
        position: relative;
        .massive-btn-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 0.5rem;
            height: 100%;
            div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    margin: 0;
                    margin-right: 0.5rem; } } }
        .search-container {
            display: flex;
            align-items: center;
            position: relative;
            margin: 0 1rem 0 0;
            span {
                display: none; }
            .custom-search-field {
                background-color: $lightText;
                padding: 0.5rem 2.5rem 0.5rem 1rem;
                border: 1px solid $grayMisc;
                color: $blackText !important;
                label {
                    input {
                        &:placeholder {
                            color: red !important; } } } }
            svg {
                color: $grayMisc;
                position: absolute;
                right: 5%; } }
        .react-datepicker-wrapper {
            width: fit-content;
            height: 100%;
            .react-datepicker__input-container {
                height: 100%;
                input {
                    padding: 0.5rem 0rem;
                    text-align: center;
                    cursor: pointer;
                    font-size: 0.75rem;
                    border: 1px solid $grayMisc;
                    border-radius: 6px;
                    height: 100%; } } }
        .react-datepicker-popper {
            z-index: 91;
            .react-datepicker {
                font-family: $main-font;
                .react-datepicker__navigation {
                    &.react-datepicker__navigation--previous,
                    &.react-datepicker__navigation--next {
                        span {
                            top: 5px; } } }
                .react-datepicker__month-container {
                    .react-datepicker__month {
                        .react-datepicker__week {
                            .react-datepicker__day {
                                &.react-datepicker__day--selected {
                                    background-color: $customerBrand; }
                                &.react-datepicker__day--range-start {
                                    background-color: $backgroundPanel; }
                                &.react-datepicker__day--range-end {
                                    background-color: $backgroundPanel; }
                                &.react-datepicker__day--in-range {
                                    background-color: $customerBrand; }
                                &.react-datepicker__day--today {} } } } } }
            #panel-btn-massive {
                border-radius: 5px;
                padding: 12px 20px!important;
                width: fit-content;
                font-size: 0.75rem; }
            .custom-range-input {
                font-size: 12px;
                border-radius: 5px;
                padding: 12px 20px;
                border: 1px solid $grayMisc;
                margin-right: 1rem; } }
        .update-btn-container {
            position: absolute;
            right: 1rem;
            button {
                background: none;
                border: none;
                color: $blackText;
                z-index: 3; } } }
    .scrollTable {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        .react-bootstrap-table {
            height: 90%;
            overflow-y: auto;
            overflow-x: hidden;
            .table {
                margin-bottom: 0; } }
        .react-bootstrap-table-pagination {
            padding: 0 1rem;
            border-top: 1px solid $grayMisc;
            height: 6vh;
            div {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                &:nth-child(1) {
                    flex-direction: row-reverse; } }
            .react-bootstrap-table-pagination-list {
                height: 6vh; }
            ul {
                margin: 0;
                background-color: transparent;
                border: none;
                .page-item {
                    width: fit-content;
                    .page-link {
                        background-color: transparent !important;
                        &:hover {
                            background-color: $backgroundLight; } }
                    &.active {
                        .page-link {
                            color: $blackText;
                            font-weight: bold; } }
                    a {
                        background-color: none;
                        border: none;
                        color: $blackText;
                        text-decoration: none; } } } }
        .pagination-total-msg {
            margin-right: 1rem;
            font-size: 0.875rem; }
        .react-bs-table-sizePerPage-dropdown {
            z-index: 3;
            button {
                border: 1px solid $grayMisc;
                background-color: transparent;
                text-align: right;
                color: $blackText;
                font-size: 0.75rem;
                padding: 0.2rem 0.7rem;
                width: 55px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative !important; }
            .dropdown-menu {
                width: fit-content;
                border: 1px solid $grayMisc;
                &.open {
                    &.show {
                        transform: translate(-24px, -165px) !important;
                        background-color: $lightText !important; } }
                .dropdown-item {
                    font-size: 0.875rem;
                    padding-right: 0;
                    padding-left: 0;
                    a {
                        text-decoration: none;
                        color: $blackText;
                        display: inline-block;
                        width: 100%;
                        padding-left: 0.5rem; } } } } }
    .custom-body-class {
        box-shadow: none;
        border-top: 1.5px solid $grayMisc; }
    .custom-header-class {
        font-size: 0.7rem !important;
        text-align: center;
        letter-spacing: 1px;
        vertical-align: middle; }
    .custom-row-class {
        font-size: 14px;
        font-size: 0.75rem;
        line-height: 21px;
        font-weight: 400;
        text-align: center;
        .actions-icons {
            width: 80%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            button {
                padding: 0 0.3rem;
                opacity: 1;
                &.disabled {
                    opacity: 0.4; }
                svg {
                    color: $invalidText; } }
            a {
                cursor: pointer;
                text-decoration: none;
                padding: 0 0.3rem;
                &.disabled {
                    opacity: 0.4; } } }
        td {
            vertical-align: sub;
            height: fit-content; }
        .external-link {
            color: #212529; }
        p {
            margin: 0; } }
    .grid-width-lg {
        width: 245px; }
    .grid-width-md {
        width: 150px; }
    .grid-width-sm-md {
        width: 160px; }
    .grid-width-sm {
        width: 80px; }
    .grid-width-xs {
        width: 50px; }
    .double-button {
        display: flex;
        justify-content: space-between;
        align-items: center; } }

.panel-format {
    padding: 0.5rem 1rem!important;
    font-size: 0.75rem !important;
    border: none;
    border-radius: 5px;
    font-weight: $bold;
    transition: background-color 0.3s ease-in-out;
    background-color: $customerBrand;
    color: $lightText;
    width: 100%;
    &:hover {
        background-color: $customerBrand-hovered; }
    &:focus,
    &:active {
        background-color: $customerBrand-active; } }

@mixin badge($status) {
    color: $status !important;
    font-size: 0.75rem !important;
    font-weight: $regular !important;
    position: relative !important;
    @media screen and ( max-width: 768px ) {
        font-size: 0.7rem !important; } }

.pending-status-badge {
    @include badge($badge-yellow); }

.created-status-badge {
    @include badge($badge-blue); }

.delivered-status-badge {
    @include badge($badge-green); }

.issue-status-badge {
    @include badge($badge-red); }

.bg-success {
    background-color: $customerBrand !important;
    font-size: 8px;
    letter-spacing: 0.8px; }

span {
    &.tooltip-parent {
        position: relative;
        .tooltip-child {
            bottom: 35px;
            right: 20%;
            width: fit-content;
            &::after {
                bottom: -10px;
                left: 45%; } } } }

.selection-cell-header,
.selection-cell {
    input[type='checkbox'] {
        width: 16px;
        height: 16px;
        position: relative;
        top: 4px;
        left: 0px;
        border-radius: 5px;
        accent-color: $customerBrand;
        &:checked {
            width: 16px;
            height: 16px;
            position: relative;
            top: 4px;
            left: 0px;
            border-radius: 5px; } } }

#action-loader {
    position: fixed;
    inset: 0;
    display: grid;
    place-items: center;
    background: hsla(0, 0, 0, .3); }

@media screen and ( max-width: 1536px ) {
    #fulltable {
        .grid-width-lg {
            width: 150px; }
        .grid-width-md {
            width: 120px; }
        .grid-width-sm-md {
            width: 100px; }
        .grid-width-sm {
            width: 80px; }
        .grid-width-xs {
            width: 50px; }
        .custom-header-class {
            font-size: 0.65rem !important;
            line-height: 0.8rem; } } }

@media screen and ( max-width: 1440px ) {
    #fulltable {
        .grid-width-sm {
            width: 70px; }
        .grid-width-xs {
            width: 40px; } } }

@media screen and ( max-width: 1366px ) {
    #fulltable {
        .grid-width-md {
            width: 110px; }
        .grid-width-sm-md {
            width: 80px; }
        .grid-width-xs {
            width: 40px; } } }

@media screen and ( max-width: 1025px ) {
    #fulltable {
        .grid-width-md {
            width: 100px; }
        .topBar-container {
            padding: 0.7rem;
            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    input {
                        margin-right: 0.3rem; } } }
            .update-btn-container {
                right: 0.3rem; } }
        .double-button {
            flex-direction: column;
            button {
                margin-bottom: 0.5rem; } } }
    .panel-format {
        padding: 0.5rem 0.5rem!important; } }

@media screen and ( max-width: 768px ) {
    #fulltable {
        .custom-row-class {
            font-size: 0.7rem;
            .actions-icons {
                width: 100%; } }
        .grid-width-lg {
            width: 100px; }
        .grid-width-md {
            width: 90px; }
        .grid-width-sm-md {
            width: 80px; }
        .grid-width-sm {
            width: 80px; }
        .topBar-container {
            padding: 0.7rem 0.5rem;
            .massive-btn-wrapper {
                width: 75%; }
            .search-container {
                width: 24%;
                margin: 0 0.3rem 0 0!important;
                .custom-search-field {
                    padding: 0.5rem 1.5rem 0.5rem 0.5rem; } }
            .react-datepicker-wrapper {
                width: fit-content;
                .react-datepicker__input-container {
                    input {
                        padding: 0.5rem 0rem;
                        font-size: 0.75rem;
                        margin-right: 0.2rem;
                        width: 100%; } } } } }
    .scrollTable {
        .react-bs-table-sizePerPage-dropdown {
            width: 22%; } }
    .panel-format {
        padding: 0.5rem !important;
        font-size: 0.65rem !important; } }
