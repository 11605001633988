#panel-m {
    height: 100vh;
    background-color: $lightText;
    position: relative;
    overflow: hidden;
    .corner-background {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0; }
    .row {
        padding: 0 1rem;
        &.row-grid {
            height: 100%;
            padding: 1rem;
            .col-12 {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                .main-nav {
                    z-index: 1;
                    padding: 1rem 0 0 0;
                    margin: 0;
                    background-color: $customerBrand;
                    border-radius: 20px;
                    height: 100%;
                    width: 20%;
                    transition: width 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
                    .action-condensedBtn {
                        width: 100%;
                        text-align: right;
                        padding-right: 0.5rem;
                        button {
                            border: none;
                            background-color: transparent;
                            svg {
                                color: $lightText; } } }
                    .logo-container {
                        padding: 1.5rem 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        img {
                            max-width: 85%;
                            margin-right: 0.5rem;
                            &:nth-child(2) {
                                border-left: 2px solid $grayMisc; }
                            &.shopify {
                                max-width: 41%;
                                padding-left: 1.1rem; }
                            &.tiendanube {
                                max-width: 12%;
                                padding-left: 0.9rem; }
                            &.vtex {
                                max-width: 6%;
                                padding-left: 0.8rem; } } }
                    &.condensed {
                        width: 7%;
                        .panel-menu {
                            a {
                                width: fit-content;
                                margin: 10% auto;
                                padding: 0.6rem;
                                justify-content: center;
                                svg {
                                    margin: 0; } } }
                        .logo-container {
                            img {
                                max-width: 85%;
                                margin-right: 0; } } } }
                .content-wrapper {
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    #rate-p,
                    #config-p,
                    #locationsConfig,
                    #locationSinglePanel {
                        padding: 3rem 4rem 0 !important; }
                    .grid-container {
                        padding: 1rem 0 0 0.5rem;
                        background-color: $lightText;
                        height: 100%; } } } } } }
.refresh {
    background: none;
    border: none;
    color: $grayMisc;
    z-index: 3;
    img {
        width: 80%; } }
.empty-msg {
    position: absolute;
    z-index: 90;
    left: 33%;
    top: 25%;
    background-color: $backgroundLight;
    border-radius: 17px;
    margin: 10% auto 1rem;
    padding: 1rem 2rem;
    text-align: center;
    width: fit-content;
    p {
        font-weight: $bold; } }


@media screen and ( max-width: 1366px ) {
    #panel-m {
        overflow: hidden;
        .row {
            &.row-grid {
                .col-12 {
                    .main-nav {
                        border-radius: 15px;
                        .logo-container {
                            padding: 1.5rem 0.7rem; } }
                    .content-wrapper {
                        #rate-p,
                        #config-p,
                        #locationsConfig {
                            padding: 2rem 3rem 0 !important; } } } } } } }

@media screen and ( max-width: 1126px ) {
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .main-nav {
                        border-radius: 10px;
                        .logo-container {
                            padding: 1.5rem 0.5rem; } }
                    .content-wrapper {
                        #rate-p,
                        #config-p,
                        #locationsConfig {
                            padding: 2rem 2rem 0 !important; } } } } } } }

@media screen and ( max-width: 1024px ) {
    #panel-m {
        .row {
            padding: 0 2rem;
            &.row-grid {
                .col-12 {
                    .main-nav {
                        border-radius: 15px;
                        .logo-container {
                            padding: 1.5rem 0.3rem;
                            img {
                                &.tiendanube {
                                    max-width: 14%;
                                    padding-left: 0.7rem; }
                                &.vtex {
                                    max-width: 8%;
                                    padding-left: 0.8rem; } } } }
                    .content-wrapper {
                        #rate-p,
                        #config-p,
                        #locationsConfig {
                            padding: 1.5rem 1.5rem 0 !important; } } } } } } }

@media screen and ( max-width: 768px ) {
    #panel-m {
        .row {
            padding: 0 1rem;
            &.row-grid {
                .col-12 {
                    .main-nav {
                        border-radius: 10px;
                        .logo-container {
                            img {
                                width: 80%;
                                &.tiendanube {
                                    max-width: 14%;
                                    padding-left: 0.7rem; }
                                &.vtex {
                                    max-width: 8%;
                                    padding-left: 0.8rem; } } } }
                    .content-wrapper {
                        #rate-p,
                        #config-p,
                        #locationsConfig {
                            padding: 1rem 1rem 0 !important; } } } } } } }
