#panel-evidence-modal {
    .modal-content {
        border-radius: 20px;
        .modal-header {
            padding: 2.5rem 2.5rem 1.5rem;
            .modal-title {
                width: 100%;
                text-align: center;
                font-weight: $bold; } }
        .modal-body,
        .modal-footer {
            width: 90%;
            margin: 0 auto;
            padding: 0; }
        .modal-body {
            padding: 0 0.5rem 2.5rem;
            .carousel {
                &.carousel-slider {
                    .control-dots {
                        top: 0;
                        .dot {
                            border: 1px solid $customerBrand;
                            box-shadow: none;
                            &.selected {
                                background-color: $customerBrand; } } }
                    .slider-container {
                        .img-wrapper {
                            width: 100%;
                            max-height: 300px;
                            overflow: hidden;
                            img {
                                width: 100%; } }
                        .legend-list {
                            list-style: none;
                            text-align: left;
                            margin: 1rem 0 0 0;
                            padding: 0;
                            color: $secondaryText;
                            .legend-item {
                                &.modal-subtitle {
                                    color: $customerBrand;
                                    margin-bottom: 0.5rem; } } } }
                    .control-prev,
                    .control-next {
                        height: 250px;
                        top: 50px; } } } }
        .modal-footer {
            padding: 1rem 0 3rem; } } }
