#locationList,
#panel-m {
    height: 100vh;
    .row {
        padding: 0;
        .content-wrapper {
            padding: 4rem 5rem 0;
            position: relative;
            height: 100%;
            .content-locations-list {
                height: 60vh;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 0.5rem 0 0; }
            .empty-msg {
                width: fit-content;
                height: fit-content;
                background-color: $backgroundLight;
                border-radius: 10px;
                margin: 10% auto 1rem;
                padding: 2rem 3rem;
                text-align: center;
                p {
                    font-weight: $bold;
                    margin: 0;
                    font-size: 1rem; } }
            .bottomPage {
                position: absolute;
                bottom: 2rem;
                right: 2.5rem; } } } }

#panel-m {
    .row {
        &.row-grid {
            .col-12 {
                .content-wrapper {
                    #locationsConfig {
                        padding: 0 !important; } } } } } }

@media screen and ( max-width: 1536px ) {
    #locationList {
        .row {
            .content-wrapper {
                .content-locations-list {
                    height: 55vh; } } } } }

@media screen and ( max-width: 1366px ) {
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .content-wrapper {
                        #locationsConfig {
                            .content-locations-list {
                                height: 50vh; } } } } } } } }

@media screen and ( max-width: 1126px ) {
    #locationList {
        .row {
            .content-wrapper {
                padding: 3rem 3rem 0;
                .content-locations-list {
                    height: 50vh; }
                .empty-msg {
                    margin: 10% auto 1rem;
                    padding: 1rem 2rem;
                    font-size: 0.875rem; } } } }
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .content-wrapper {
                        #locationsConfig {
                            .content-locations-list {
                                height: 45vh; } } } } } } } }

@media screen and ( max-width: 768px ) {
    #locationList {
        .row {
            .content-wrapper {
                padding: 2rem 1rem 0; } } } }
