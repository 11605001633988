#finish {
    height: 100vh;
    .row {
        padding: 0;
        height: 100%;
        .content-wrapper {
            padding: 0 5rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .finish-wrapper {
                width: 80%;
                text-align: center;
                margin: 0 auto;
                .text-wrapper {
                    margin-bottom: 4rem; }
                .notification {
                    background-color: $warningBackground;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0.5rem 80px 0.5rem 30px;
                    img {
                        width: 15%; }
                    .notification-text-wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: $warningText;
                        font-size: 1rem;
                        p {
                            margin: 0; } } }
                .bottomPage {
                    margin-top: 4rem;
                    div {
                        justify-content: center!important; } } } } } }

@media screen and ( max-width: 1366px ) {
    #finish {
        .row {
            .content-wrapper {
                .finish-wrapper {
                    .notification {
                        padding: 0.5rem 25px 0.5rem 10px; }
                    .text-wrapper {
                        margin-bottom: 0; }
                    img {
                        width: 50%; }
                    .bottomPage {
                        margin-top: 3rem; } } } } } }
