.logo-container {
    padding: 0;
    margin-left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    img {
        max-width: 50%;
        margin-right: 0.8rem;
        &:nth-child(2) {
            border-left: 2px solid #cccccc; }
        &.shopify {
            max-width: 41%;
            padding-left: 1.1rem; }
        &.tiendanube {
            max-width: 51%;
            padding-left: 0.9rem; }
        &.vtex {
            max-width: 25%;
            padding-left: 0.8rem; } } }

@media screen and ( max-width: 1367px ) {
    .logo-container {
        img {
            max-width: 48%;
            &.shopify {
                max-width: 41%;
                padding-left: 1.1rem; }
            &.tiendanube {
                max-width: 51%;
                padding-left: 1rem; }
            &.vtex {
                max-width: 28%; } } } }

@media screen and ( max-width: 1281px ) {
    .logo-container {
        img {
            max-width: 48%; } } }

@media screen and ( max-width: 1024px ) {
    .logo-container {
        img {
            max-width: 50%;
            margin-right: 0.5rem;
            &.vtex {
                max-width: 32%;
                padding-left: 0.5rem; } } } }
