#rates,
#panel-m {
    height: 100vh;
    .row {
        padding: 0;
        .content-wrapper {
            padding: 4rem 5rem 0;
            height: 100%;
            .rates-form {
                position: relative;
                height: 80vh;
                .accordionHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .accordion-btn {
                        border: none;
                        background: none;
                        color: $customerBrand; } }
                .form-check {
                    label {
                        color: $customerBrand!important; } }
                .feeFixed-phrase-shop,
                .promo-phrase-shop {
                    width: fit-content;
                    display: grid;
                    grid-template-columns: 1fr 0.5fr;
                    align-items: center;
                    text-align: center;
                    margin-left: 0.5rem;
                    margin-bottom: 1rem;
                    span {
                        padding: 0 10px;
                        color: $customerBrand; }
                    div {
                        display: flex;
                        align-items: center;
                        input {
                            width: 100px;
                            background-color: $lightText;
                            border: 1px solid $customerBrand;
                            padding: 0.5rem; } } }
                .promo-phrase-shop {
                    width: 75%;
                    display: grid;
                    grid-template-columns: auto 0.5fr auto auto;
                    align-items: center;
                    text-align: center;
                    margin-left: 1rem;
                    span {
                        margin-bottom: 0;
                        padding: 0 10px; }
                    label {
                        &:nth-child(1) {
                            display: none; } }
                    .select-wrapper {
                        padding: 0; }
                    .radios-wrapper {
                        width: fit-content;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid $customerBrand; } }
                .promo-phrase-shop {
                    width: fit-content;
                    margin-bottom: 0; }
                .bottomPage {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    &.doubleBtn {
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a {
                            text-decoration: none; } } }
                .promotional-container {
                    border-top: 1px solid $blackText;
                    padding-top: 2rem; } } } } }


@media screen and ( max-width: 1126px ) {
    #rates {
        .row {
            .content-wrapper {
                padding: 2rem 3rem 0; } } } }

@media screen and ( max-width: 1024px ) {
    .rates-form {
        .form-check-label {
            font-size: 0.75rem; } } }

