#welcome {
    height: 100vh;
    .row {
        height: 100%;
        .col {
            padding: 0 4rem;
            display: flex;
            flex-direction: column;
            justify-content: center; }
        .welcome-grid {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: $customerBrand;
            border-radius: 0 20px 20px 0;
            padding: 5rem 4.5rem;
            .welcome-text {
                margin-top: 20%;
                h1 {
                    margin-bottom: 2rem; } }
            .illustration-wrapper {
                margin-top: 20%; } }
        .form-col {
            .form-wrapper {
                border-radius: 14px;
                width: 60%;
                margin: 0 auto;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                background-color: white;
                p {
                    padding: 0 1rem;
                    text-align: center;
                    margin-bottom: 0; }
                form {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 5rem 3rem 4rem;
                    text-align: left;
                    margin: 2.5rem 0 0;
                    box-shadow: 0px 0px 10px rgba(173, 173, 214, 0.3);
                    border-radius: 25px;
                    .inputSimple-main-container {
                        margin-bottom: 8%; }
                    .login-btn {
                        margin-top: 7%;
                        div {
                            justify-content: center !important; } } } } } } }

.illustration-wrapper {
    width: 100%;
    img {
        max-width: 100%; } }

@media screen and ( max-width: 1680px ) {
    #welcome {
        .row {
            .form-col {
                .form-wrapper {
                    width: 71%; } } } } }

@media screen and ( max-width: 1440px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {}
            .welcome-grid {
                padding: 4rem 3.5rem;
                .welcome-text {
                    h1 {
                        margin-bottom: 1rem; } }
                .illustration-wrapper {
                    margin-top: 30%;
                    width: 110%; } } } } }

@media screen and ( max-width: 1367px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {}
            .welcome-grid {
                padding: 3rem 2.5rem;
                .welcome-text {
                    margin-top: 15%;
                    h1 {
                        margin-bottom: 1rem; } }
                .illustration-wrapper {
                    margin-top: 10%;
                    width: 90%; } }
            .form-col {
                .form-wrapper {
                    form {
                        margin: 2.5rem 0 0;
                        padding: 4rem 3rem 3.5rem; } } } } } }

@media screen and ( max-width: 1281px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {
                padding: 1.5rem 3rem; }
            .welcome-grid {
                padding: 3rem 2.5rem; }
            .form-col {
                .form-wrapper {
                    form {
                        padding: 3rem 3rem 2.5rem; } } } } } }

@media screen and ( max-width: 1025px ) {
    #welcome {
        overflow: hidden;
        .row {
            .col {
                padding: 1.5rem 2rem; }
            .welcome-grid {
                padding: 3rem 2.5rem;
                .illustration-wrapper {
                    width: 80%; } }
            .form-col {
                .form-wrapper {
                    width: 75%;
                    form {
                        padding: 3rem 3rem 2rem;
                        .inputSimple-main-container {
                            margin-bottom: 4%; } } } } } } }

@media screen and ( max-width: 768px ) {
    #welcome {
        .row {
            .col {
                padding: 1.8rem 1.5rem; }
            .welcome-grid {
                padding: 2.5rem 2rem; }
            .form-col {
                .form-wrapper {
                    width: 80%;
                    form {
                        margin: 0.7rem 0 0;
                        padding: 2.5rem 2.5rem 1.5rem; } } } } } }
