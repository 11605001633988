#panel-confirm-modal {
    .modal-content {
        border-radius: 20px;
        .modal-header {
            padding: 2rem 2rem 0 0; }
        .modal-body,
        .modal-footer {
            width: 70%;
            margin: 0 auto; }
        .modal-body {
            text-align: center;
            &.intro-title {
                color: $blackText; } }
        .modal-footer {
            padding: 3rem 2rem 4rem; } } }

@media screen and ( max-width: 1366px ) {
    #panel-confirm-modal {
        padding: 2rem;
        .modal-content {
            .modal-footer {
                padding: 2.5rem 2rem 3rem; } } } }

@media screen and ( max-width: 1280px ) {
    #panel-confirm-modal {
        padding: 5rem;
        .modal-content {
            .modal-header {
                padding: 1.5rem 1.5rem 0 0; }
            .modal-footer {
                padding: 1.5rem 2rem 2.5rem; } } } }

@media screen and ( max-width: 1024px ) {
    #panel-confirm-modal {
        padding: 1rem;
        .modal-content {
            .modal-header {
                padding: 1rem 1rem 0 0; }
            .modal-body,
            .modal-footer {
                width: 80%;
                margin: 0 auto; }
            .modal-footer {
                padding: 1rem 2rem 2rem; } } } }
