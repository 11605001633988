.panel-menu {
    padding: 0.5rem;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    a {
        text-decoration: none;
        border-radius: 6px;
        color: $lightText;
        padding: 0.875rem 1rem;
        margin: 3% auto;
        font-size: 0.875rem;
        line-height: 0;
        font-weight: $regular;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
    svg {
        margin-right: 0.5rem; }
    .menu-selected {
        background-color: $customerBrand-secondary;
        font-weight: $bold;
        border-radius: 6px; } }

@media screen and ( max-width: 1025px ) {
    .panel-menu {
        a {
            padding: 0.75rem 0.5rem;
            font-size: 0.75rem; } } }

@media screen and ( max-width: 768px ) {
    .panel-menu {
        a {
            font-size: 0.7rem;
            padding: 0.7rem 0.4rem; } } }

