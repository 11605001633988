#configurations,
#panel-m {
    height: 100vh;
    .row {
        padding: 0;
        height: 100%;
        .content-wrapper {
            padding: 3rem 4rem 0;
            height: 100%;
            .configurations-form {
                position: relative;
                height: 80vh;
                .accordionHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .accordion-btn {
                        border: none;
                        background: none;
                        color: $customerBrand; } }
                #settings {
                    padding-bottom: 1rem;
                    border-bottom: 1px solid $blackText;
                    .radio-setting {
                        div {
                            &:nth-child(1) {
                                margin-bottom: 0!important;
                                font-weight: $medium; } }
                        p {
                            margin-left: 1.5rem;
                            color: $customerBrand; }
                        .form-check {
                            label {
                                color: $customerBrand!important; } } } }
                #package {
                    .packageRadio-container {
                        background-color: $backgroundLight;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        list-style: none;
                        padding: 0;
                        border-radius: 10px;
                        width: 50%;
                        li {
                            border-bottom: 1px solid $grayMisc;
                            width: 100%;
                            padding: 2rem;
                            margin: 0;
                            width: 95%;
                            margin: 0 auto;
                            &:last-child {
                                border: none; }
                            .form-check {
                                margin-bottom: 0 !important;
                                label {
                                    font-size: 1rem; } }
                            p {
                                margin: 0 0 0 1.5rem;
                                color: $secondaryText; } } } }
                .bottomPage {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    &.doubleBtn {
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a {
                            text-decoration: none; } } } } } } }

@media screen and ( max-width: 1680px ) {
    #configurations {
        .row {
            .content-wrapper {
                padding: 3.5rem 4.5rem 0;
                .configurations-form {
                    height: 85vh;
                    #package {
                        .packageRadio-container {
                            li {
                                padding: 1.5rem; } } } } } } } }


@media screen and ( max-width: 1366px ) {
    .configurations-form {
        #package {
            .packageRadio-container {
                width: 60%;
                li {
                    padding: 1rem; } } } }
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .content-wrapper {
                        .configurations-form {
                            #package {
                                .packageRadio-container {
                                    width: 50%;
                                    li {
                                        padding: 0.8rem 1rem; } } } } } } } } } }


@media screen and ( max-width: 1126px ) {
    #configurations {
        .row {
            .content-wrapper {
                padding: 2rem 3rem 0;
                .configurations-form {
                    #package {
                        .packageRadio-container {
                            li {
                                padding: 0.5rem;
                                .form-check {
                                    label {
                                        font-size: 0.875rem; } }
                                p {
                                    font-size: 0.7rem; } } } } } } } }
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .content-wrapper {
                        .configurations-form {
                            #package {
                                height: 60%;
                                overflow-x: hidden;
                                overflow-y: auto;
                                .packageRadio-container {
                                    li {
                                        .form-check {
                                            label {
                                                font-size: 0.875rem; } } } } } } } } } } } }

@media screen and ( max-width: 1024px ) {
    #configurations {
        .row {
            .content-wrapper {
                padding: 2rem 3rem 0;
                .configurations-form {
                    #package {
                        .packageRadio-container {
                            li {
                                padding: 0.5rem; } } } } } } }
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .content-wrapper {
                        .configurations-form {
                            .packageRadio-container {
                                .form-check {
                                    label {
                                        font-size: 0.9rem !important; } } } } } } } } } }

@media screen and ( max-width: 768px ) {
    #configurations {
        .row {
            .content-wrapper {
                padding: 2rem 3rem 0;
                .configurations-form {
                    #package {
                        .packageRadio-container {
                            li {
                                padding: 0.3rem;
                                .form-check {
                                    label {
                                        font-size: 0.8rem; }
                                    input {
                                        width: 10px;
                                        height: 10px; } }
                                p {
                                    font-size: 0.6rem; } } } } } } } }
    #panel-m {
        .row {
            &.row-grid {
                .col-12 {
                    .content-wrapper {
                        .configurations-form {
                            #settings {
                                padding-bottom: 0.5rem;
                                .radio-setting {
                                    .form-check {
                                        label {
                                            font-size: 0.8rem; } }
                                    p {
                                        font-size: 0.75rem; } } }
                            #package {
                                height: 65%;
                                overflow-x: hidden;
                                overflow-y: auto; } } } } } } } }
