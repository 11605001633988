* {
  font-variant-ligatures: none; }

body {
    font-family: $main-font;
    color: $blackText;
    font-weight: $regular;
    .main-title {
        font-size: 2.25rem;
        line-height: 2.75rem;
        font-weight: $bold; }
    .main-subtitle {
        font-size: 1.125rem;
        line-height: 1.375rem;
        color: $secondaryText; }
    .modal-subtitle {
        font-size: 1.5rem;
        line-height: 1.813rem;
        font-weight: $bold; }
    .intro-title {
        font-size: 2rem;
        line-height: 2.438rem;
        color: $lightText;
        font-weight: $bold; }
    .intro-subtitle {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: $lightText; }
    .invalid-msg {
        font-size: 0.875rem;
        line-height: 1rem;
        display: inline-block;
        width: 100%;
        text-align: left;
        color: $invalidText !important; }
    .small-subtitle {
        font-size: 0.875rem;
        line-height: 1.2rem; } }

@media screen and ( max-width: 1441px ) {
    body {
        .main-title {
            font-size: 1.875rem;
            line-height: 2.375rem; }
        .main-subtitle {
            font-size: 1.125rem;
            line-height: 1.375rem; }
        .modal-subtitle {
            font-size: 1.3rem;
            line-height: 1.375rem; }
        .intro-title {
            font-size: 1.75rem;
            line-height: 2.188rem; }
        .intro-subtitle {
            font-size: 1rem;
            line-height: 1.3rem; } } }

@media screen and ( max-width: 1367px ) {
    body {
        .main-title {
            font-size: 1.6rem;
            line-height: 1.8rem; }
        .main-subtitle {
            font-size: 1rem;
            line-height: 1.25rem; }
        .intro-title {
            font-size: 1.5rem;
            line-height: 1.875rem; }
        .intro-subtitle {
            font-size: 0.875rem;
            line-height: 1.2rem; }
        .small-subtitle {
            font-size: 0.75rem;
            line-height: 1rem; } } }

@media screen and ( max-width: 1025px ) {
    body {
        .main-subtitle {
            font-size: 0.75rem;
            line-height: 1rem; }
        .intro-title {
            font-size: 1.25rem;
            line-height: 1.563rem; }
        .intro-subtitle {
            font-size: 0.875rem;
            line-height: 1rem; }
        .small-subtitle {
            font-size: 0.65rem;
            line-height: 0.8rem; } } }

@media screen and ( max-width: 768px ) {
    body {
        .main-title {
            font-size: 1.2rem;
            line-height: 1.3rem; }
        .main-subtitle {
            font-size: 0.75rem;
            line-height: 0.875rem; }
        .intro-title {
            font-size: 1.125rem;
            line-height: 1.25rem; }
        .intro-subtitle {
            font-size: 0.75rem;
            line-height: 1rem; } } }
