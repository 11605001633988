// Colors
$backgroundDark: #343534;
$backgroundLight: #EDEDFC;
$backgroundPanel: #ebebeb;
$backgroundLightPurple: #F3EDFF;

$grayMisc: #B9B9C3;

$lightText: #fcfcfc;
$blackText: #1A1A38;
$secondaryText: #62628A;
$invalidText: #994742;
$validText: #41CC94;

///

$customerBrand: #7145D6;
$customerBrand-disabled: #8383AD;
$customerBrand-hovered: #5c39af;
$customerBrand-active: #472c86;

$customerBrand-secondary: #44307A;

///

$navItems: #B696FF;

$alertErrorText: #5C3747;
$backgroundError: #FFDEDB;

$alertSuccessText: #275252;
$alertSuccessBackground: #D2F5E7;

$blueText: #558CD5;
$backgroundBlue: #E5F0FF;

$warningBackground: #FEFFB6;
$warningText: #5C4F42;

//

$badge-blue: #325E9F;
$badge-green: #2A7B5C;
$badge-yellow: #C48F16;
$badge-red: #994742;

$restoreBtn: #71c59f;
$restoreBtn-hovered: #58a583;
$restoreBtn-activated: #437e63;

$cancelBtn: rgba(245, 47, 65, 0.5);
$cancelBtn-hovered: rgba(216, 42, 57, 0.5);
$cancelBtn-activated: rgba(151, 29, 39, 0.5);

$fulfillBtn: #FF8672;
$fulfillBtn-hovered: #ce6c5b;
$fulfillBtn-activated: #a7584a;

$payBtn: #24A0ED;
$payBtn-hovered: #1e86c7;
$payBtn-activated: #196b9e;

// Fonts

@font-face {
    font-family: 'CabifyCircular';
    src: local('CabifyCircular'), url(./fonts/CabifyCircular-Bold.otf) format('opentype');
    font-weight: 700; }

@font-face {
    font-family: 'CabifyCircular';
    src: local('CabifyCircular'), url(./fonts/CabifyCircular-Book.otf) format('opentype');
    font-weight: 400; }

@font-face {
    font-family: 'CabifyCircular';
    src: local('CabifyCircular'), url(./fonts/CabifyCircular-Light.otf) format('opentype');
    font-weight: 300; }

@font-face {
    font-family: 'CabifyCircular';
    src: url("./fonts/CabifyCircular-Bold.otf");
    src: url("./fonts/CabifyCircular-Book.otf");
    src: url("./fonts/CabifyCircular-Light.otf"); }


$main-font: 'CabifyCircular';
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
