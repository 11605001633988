input {
    color: $blackText !important; }

.input-wrapper {
    position: relative; }

.form-control,
.form-select {
    border-radius: 7px;
    border: none;
    font-size: 1rem;
    background-color: $backgroundLight;
    &:focus {
        background-color: white;
        border: 1px solid $customerBrand;
        &::placeholder {
            opacity: 1; } }
    &.padding-lg {
        padding: 2rem 1.6rem 1rem; }
    &::placeholder {
        opacity: 0; }
    &:disabled {
        background-color: $grayMisc; } }

.icon-parent {
    position: relative;
    .icon-child {
        position: absolute;
        top: -30px;
        right: 10px;
        cursor: pointer;
        z-index: 3; } }

.form-label {
    font-size: 1.125rem;
    position: absolute;
    top: 15%;
    left: 5%;
    transition: all 0.2s ease-in-out;
    color: $customerBrand;
    &.moveUp {
        top: 15%;
        font-size: 0.7rem; }
    &.moveDown {
        top: 40%;
        font-size: 1.125rem; }
    &.forTextArea {
        &.moveUp {
            top: 10%;
            left: 2.2%;
            font-size: 0.7rem; }
        &.moveDown {
            top: 20%;
            left: 2.2%;
            font-size: 1.125rem; } }
    &.label-bold {
        font-weight: $bold; } }


.form-check-label,
.form-duration-label {
    font-size: 0.9rem;
    &.label-bold {
        font-weight: $bold; } }

.form-check-input {
    border-color: $customerBrand;
    cursor: pointer;
    &:checked {
        background-color: $customerBrand;
        border-color: $customerBrand; } }

.radioBtn-wrapper {
    input {
        opacity: 0;
        position: fixed;
        width: 0;
        &:checked + label {
            background-color: $customerBrand-secondary;
            color: $lightText; } }
    label {
        color: $customerBrand-secondary;
        cursor: pointer;
        font-size: 1.125rem;
        font-weight: $bold;
        padding: 1rem 2rem;
        background-color: transparent;
        border: 1px solid $customerBrand-secondary;
        border-radius: 8px;
        transition: background-color 0.3s ease-in-out;
        width: fit-content; }
    &.disabled {
        label {
            background-color: $grayMisc;
            cursor: auto; } }
    &.rounded {
        label {
            border-radius: 100%;
            width: 70px;
            height: 70px;
            padding: 1rem;
            line-height: 40px;
            text-align: center; } } }

textarea {
    resize: none; }

.is-valid {
    background-image: url('../../assets/icons/icon-input-valid.svg') !important;
    background-position: center right 5% !important;
    border: 1px solid $customerBrand !important; }

.is-invalid {
    background-image: url('../../assets/icons/icon-input-invalid.svg') !important;
    background-position: center right 5% !important;
    border: 1px solid $customerBrand !important; }

@media screen and ( max-width: 1367px ) {
    .form-control,
    .form-select {
        font-size: 1rem;
        &.padding-lg {
            padding: 1.8rem 1.2rem 0.5rem; } }

    .form-label {
        font-size: 1rem;
        &.moveDown {
            font-size: 1rem; }
        &.forTextArea {
            &.moveDown {
                font-size: 1rem; } } }

    .radioBtn-wrapper {
        label {
            font-size: 1rem;
            padding: 0.8rem 1.5rem; } } }

@media screen and ( max-width: 1280px ) {
    .radioBtn-wrapper {
        label {
            font-size: 0.875rem;
            padding: 0.8rem 1.5rem; }
        &.rounded {
            label {
                width: 60px;
                height: 60px;
                padding: 0.65rem; } } }
    .form-check-label,
    .form-duration-label {
        font-size: 0.85rem; } }

@media screen and ( max-width: 1183px ) {
    .form-control,
    .form-select {
        font-size: 0.8rem;
        &.padding-lg {
            padding: 1.4rem 1.2rem 0.5rem; } }

    .form-label {
        font-size: 0.8rem;
        &.moveUp {
            font-size: 0.5rem; }
        &.moveDown {
            font-size: 0.8rem; }
        &.forTextArea {
            &.moveUp {
                font-size: 0.5rem; }
            &.moveDown {
                font-size: 0.8rem; } } }

    .form-check-label,
    .form-duration-label {
        font-size: 0.8rem; } }

@media screen and ( max-width: 1024px ) {
    .radioBtn-wrapper {
        label {
            font-size: 0.875rem;
            padding: 0.7rem 1.2rem; }
        &.rounded {
            label {
                width: 60px;
                height: 60px;
                padding: 0.65rem; } } }

    .form-check-label {
        font-size: 0.7rem; } }

@media screen and ( max-width: 768px ) {
    .form-control,
    .form-select {
        font-size: 0.5rem;
        &.padding-lg {
            padding: 1rem 0.8rem 0.5rem; } }

    .form-label {
        font-size: 0.5rem;
        &.moveDown {
            font-size: 0.5rem; }
        &.forTextArea {
            &.moveDown {
                font-size: 0.5rem; } } }

    .radioBtn-wrapper {
        label {
            font-size: 0.7rem;
            padding: 0.5rem 0.9rem; }
        &.rounded {
            label {
                width: 45px;
                height: 45px;
                padding: 0.25rem; } } }

    .form-check-label {
        font-size: 0.65rem; } }
