#locationSingle,
#panel-m {
    height: 100vh;
    .row {
        padding: 0;
        .content-wrapper,
        #locationSinglePanel {
            padding: 4rem 5rem 0;
            height: 100vh;
            overflow: hidden;
            #InCoverageFlowContainer {
                height: 90%;
                #location-form,
                #location-timeAndMethod-form {
                    height: 100%;
                    position: relative;
                    .form-grid-two-columns {
                        padding: 0.5rem 0rem;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 4rem; }
                    .bottomPage {
                        background-color: white;
                        position: absolute;
                        bottom: -2px;
                        right: 0;
                        padding: 0.5rem 0;
                        &.doubleBtn {
                            width: 100%;
                            margin: 0 auto;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            a {
                                text-decoration: none; } } } }
                #location-timeAndMethod-form {
                    .form-blocks-container {
                        height: 55vh;
                        overflow-y: auto;
                        overflow-x: hidden;
                        margin-top: 1rem;
                        padding: 0 0.5rem 0 0;
                        .preparationTime-container {
                            background-color: $backgroundLightPurple;
                            padding: 2rem;
                            border-radius: 10px;
                            .radioBtns-personalized {
                                padding: 1.5rem 0 0 0;
                                width: 50%;
                                display: grid;
                                grid-template-columns: 120px 100px auto;
                                align-items: center;
                                p {
                                    margin: 0;
                                    padding: 0 0.5rem; } }
                            .radioBtns-personalized {
                                input {
                                    background-color: $lightText;
                                    border: 1px solid $customerBrand;
                                    color: $blackText; } } }
                        .weekdays-container {
                            background-color: $backgroundLightPurple;
                            padding: 2rem 2.5rem;
                            border-radius: 10px;
                            .week-hours-container {
                                display: grid;
                                grid-template-columns: 80px auto;
                                align-items: center;
                                margin-top: 1rem;
                                .personalized-week-hours-wrapper {
                                    padding: 0;
                                    width: 100%;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    .invalid-msg {
                                        text-align: left !important; }
                                    .react-datepicker-wrapper {
                                        width: fit-content; }
                                    .react-datepicker__input-container {
                                        width: 100px;
                                        display: flex;
                                        justify-content: center;
                                        margin-left: 0; }
                                    .custom-picker-input {
                                        width: 100px;
                                        background-color: $lightText;
                                        border-radius: 5px;
                                        border: 1px solid $customerBrand;
                                        padding: 0.5rem;
                                        margin: 0;
                                        text-align: center;
                                        cursor: pointer; }
                                    .react-datepicker-popper {
                                        width: 150px;
                                        cursor: pointer;
                                        z-index: 99;
                                        .react-datepicker {
                                            &.react-datepicker--time-only {
                                                width: 150px;
                                                .react-datepicker__triangle {
                                                    display: none; }
                                                .react-datepicker__time-container {
                                                    width: 150px;
                                                    .react-datepicker__header {
                                                        &.react-datepicker__header--time {
                                                            &.react-datepicker__header--time--only {
                                                                display: none; } } }
                                                    .react-datepicker__time-box {
                                                        width: 150px;
                                                        height: 150px;
                                                        overflow-x: hidden;
                                                        overflow-y: auto;
                                                        background-color: $lightText;
                                                        border: 1px solid $customerBrand;
                                                        color: $blackText;
                                                        font-family: $main-font;
                                                        padding: 0;
                                                        z-index: 99;
                                                        text-align: center;
                                                        border-radius: 5px;
                                                        &::-webkit-scrollbar {
                                                            width: 0px; }
                                                        .react-datepicker__time-list {
                                                            font-size: 1rem;
                                                            list-style: none;
                                                            padding: 0;
                                                            .react-datepicker__time-list-item {
                                                                padding: 0.3rem;
                                                                &:hover {
                                                                    background-color: $customerBrand-secondary;
                                                                    color: $lightText; }
                                                                &.react-datepicker__time-list-item--selected {
                                                                    background-color: $backgroundLightPurple;
                                                                    color: $blackText;
                                                                    font-weight: $bold; } } } } } } } } }
                                input {
                                    width: 100px !important;
                                    cursor: pointer; }
                                p {
                                    margin: 0;
                                    padding: 0 0.8rem;
                                    text-align: center; } }
                            .general {
                                grid-template-columns: 170px auto; } }
                        .selectorAdder {
                            div {
                                justify-content: flex-start !important;
                                button {
                                    text-align: left;
                                    padding: 0;
                                    text-decoration: underline;
                                    font-weight: $regular;
                                    &:hover {
                                        background-color: transparent;
                                        border: none; }
                                    &:active,
                                    &:focus {
                                        background-color: transparent;
                                        color: $customerBrand; } } } }
                        .automaticSetter-container {
                            background-color: $backgroundBlue;
                            padding: 1.5rem;
                            border-radius: 10px;
                            color: $blueText;
                            border-left: 10px solid $blueText;
                            div {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .illustration-and-text {
                                    .illustration-wrapper {
                                        width: fit-content;
                                        margin-right: 1rem; }
                                    .text-wrapper {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: flex-start;
                                        .intro-subtitle {
                                            color: $blueText;
                                            font-weight: $bold; }
                                        p {
                                            margin: 0; } } }
                                .form-check-input {
                                    border-color: $blueText;
                                    cursor: pointer;
                                    height: 30px;
                                    width: 50px;
                                    &:checked {
                                        background-color: $blueText;
                                        border-color: $blueText; } } } }
                        .configAll-setter {
                            padding: 0 1rem;
                            .form-check {
                                label {
                                    text-decoration: underline; } } } } }
                .radioAsBtns-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    div {
                        margin-right: 1rem;
                        &:last-child {
                            margin-right: 0; } } } }
            #outOfCoverage {
                height: 95%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                position: relative;
                .bottomPage {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    &.doubleBtn {
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a {
                            text-decoration: none; } } }
                .outOfCoverage-wrapper {
                    width: 100%;
                    height: 550px;
                    border: 1px solid $grayMisc;
                    border-radius: 10px;
                    padding: 2rem;
                    margin-top: 10%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    .intro-subtitle {
                        color: $blackText;
                        font-weight: $bold; }
                    .form-grid-two-columns {
                        padding: 0.5rem 0rem;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        column-gap: 4rem; }
                    .goBack-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        .goBack-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            svg {
                                color: $customerBrand; }
                            button {
                                padding-left: 0.5rem; } } }
                    .formAndMap-wrapper {
                        margin: 1.5rem 0 1rem 0;
                        height: 80%;
                        .address-verfiy-form {
                            position: relative;
                            .checkAddress-btn {
                                position: absolute;
                                top: 0;
                                left: 53%; }
                            .places-container {
                                margin-bottom: 1.5rem;
                                width: 100%;
                                height: 100%;
                                input {
                                    border-radius: 5px;
                                    border: 1px solid $customerBrand;
                                    padding: 1.1rem;
                                    width: 50%; } }
                            .map-container {
                                width: 100%;
                                height: 300px;
                                margin-bottom: 1rem;
                                .leaflet-container {
                                    height: 100%;
                                    .leaflet-control-container {
                                        .leaflet-bottom {
                                            &.leaflet-right {
                                                .leaflet-control-attribution {
                                                    &.leaflet-control {
                                                        a {
                                                            &:first-child {
                                                                display: none; } }
                                                        span {
                                                            display: none; } } } } } } } } } } } } } } }

#panel-m {
    .row {
        #locationSinglePanel {
            #InCoverageFlowContainer {
                #location-form,
                #location-timeAndMethod-form {
                    padding-top: 0; } } } } }

// @media screen and ( max-width: 1680px )
//     #locationSingle,
//     #panel-m
//         .row
//             .content-wrapper,
//             #locationSinglePanel
//                 #InCoverageFlowContainer
//                     #location-timeAndMethod-form
//                         padding-top: 0
//                         .form-blocks-container
//                             height: 50vh
//                 #outOfCoverage
//                     .outOfCoverage-wrapper
//                         height: 480px
//                         .intro-subtitle
//                         .formAndMap-wrapper
//                             .address-verfiy-form
//                                 .map-container
//                                     width: 100%
//                                     height: 280px

@media screen and ( max-width: 1536px ) {
    #locationSingle,
    #panel-m {
        .row {
            .content-wrapper,
            #locationSinglePanel {
                #InCoverageFlowContainer {
                    #location-timeAndMethod-form {
                        padding-top: 0;
                        .form-blocks-container {
                            height: 50vh;
                            margin-top: 0.5rem; } } }
                #outOfCoverage {
                    .outOfCoverage-wrapper {
                        height: 450px;
                        .intro-subtitle {}
                        .formAndMap-wrapper {
                            .address-verfiy-form {
                                .places-container {
                                    input {
                                        padding: 0.7rem; } }
                                .map-container {
                                    height: 240px; } } } } } } } } }

@media screen and ( max-width: 1366px ) {
    #locationSingle,
    #panel-m {
        .row {
            .content-wrapper,
            #locationSinglePanel {
                padding: 3rem 4rem 0;
                #InCoverageFlowContainer {
                    #location-timeAndMethod-form {
                        .form-blocks-container {
                            .preparationTime-container {
                                padding: 1.5rem; }
                            .weekdays-container {
                                padding: 1.5rem; }
                            .automaticSetter-container {
                                padding: 1rem; } } } }
                #outOfCoverage {
                    .outOfCoverage-wrapper {
                        height: 450px;
                        margin-top: 5%;
                        .intro-subtitle {}
                        .formAndMap-wrapper {
                            margin: 1rem 0 0;
                            .address-verfiy-form {
                                .places-container {
                                    margin-bottom: 1rem; }
                                .map-container {
                                    height: 240px; } } } } } } } } }

@media screen and ( max-width: 1280px ) {
    #locationSingle,
    #panel-m {
        .row {
            .content-wrapper,
            #locationSinglePanel {
                padding: 3rem 4rem 0;
                #InCoverageFlowContainer {
                    #location-timeAndMethod-form {
                        .form-blocks-container {
                            .preparationTime-container {
                                padding: 1rem; } }
                        .weekdays-container {
                            padding: 1rem;
                            .week-hours-container {
                                .personalized-week-hours-wrapper {
                                    .invalid-msg {
                                        font-size: 0.7rem; }
                                    .custom-picker-input {
                                        font-size: 0.75rem; } } } } } }
                #outOfCoverage {
                    .outOfCoverage-wrapper {
                        height: 400px;
                        padding: 1rem;
                        .formAndMap-wrapper {
                            .address-verfiy-form {
                                .places-container {}
                                .map-container {
                                    width: 100%;
                                    height: 200px; } } } } } } } } }

@media screen and ( max-width: 1126px ) {
    #locationSingle,
    #panel-m {
        .row {
            .content-wrapper,
            #locationSinglePanel {
                padding: 3rem 3rem 0;
                #InCoverageFlowContainer {
                    #location-timeAndMethod-form {
                        .form-blocks-container {
                            height: 45vh; } } }
                #outOfCoverage {
                    .outOfCoverage-wrapper {
                        height: 330px; } } } } } }

@media screen and ( max-width: 1024px ) {
    #locationSingle,
    #panel-m {
        .row {
            .content-wrapper,
            #locationSinglePanel {
                #InCoverageFlowContainer {
                    #location-form,
                    #location-timeAndMethod-form {
                        .form-blocks-container {
                            height: 50vh;
                            .preparationTime-container {
                                padding: 1rem; }
                            .weekdays-container {
                                padding: 1rem;
                                .week-hours-container {
                                    .personalized-week-hours-wrapper {
                                        .invalid-msg {
                                            font-size: 0.75rem;
                                            line-height: 0.9; }
                                        .custom-picker-input {
                                            padding: 0.3rem;
                                            font-size: 0.75rem; } } } }
                            .automaticSetter-container {
                                padding: 1rem;
                                div {
                                    margin-left: 1rem;
                                    .form-check-input {
                                        height: 20px;
                                        width: 35px; } }
                                .illustration-and-text {
                                    margin-left: 0; } } }
                        .form-grid-two-columns {
                            grid-gap: 2rem; } } }
                #outOfCoverage {
                    .outOfCoverage-wrapper {
                        .formAndMap-wrapper {
                            .address-verfiy-form {
                                .places-container {
                                    input {
                                        padding: 0.5rem; } } } } } } } } } }

@media screen and ( max-width: 768px ) {
    #locationSingle,
    #panel-m {
        .row {
            .content-wrapper,
            #locationSinglePanel {
                padding: 1.5rem 1.5rem 0;
                #InCoverageFlowContainer {
                    #location-form,
                    #location-timeAndMethod-form {
                        .form-blocks-container {
                            .preparationTime-container {
                                padding: 1rem; }
                            .weekdays-container {
                                padding: 0.5rem;
                                .week-hours-container {
                                    p {
                                        font-size: 0.75rem; }
                                    .personalized-week-hours-wrapper {
                                        p {
                                            font-size: 0.75rem; }
                                        .invalid-msg {
                                            font-size: 0.75rem;
                                            line-height: 0.9; }
                                        .custom-picker-input {
                                            padding: 0.3rem;
                                            font-size: 0.7rem; } } } } } } }
                #outOfCoverage {
                    .outOfCoverage-wrapper {
                        margin-top: 0; } } } } } }
