.locationItem-grid {
    width: 100%;
    padding: 1.1rem 2rem 1rem;
    text-align: left;
    background-color: white;
    border: 1px solid $grayMisc;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1.5fr 2.5fr 1fr;
    justify-content: center;
    align-items: center;
    .address-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        h3 {
            font-size: 0.875rem;
            margin-bottom: 0; }
        small {
            display: inline-block;
            font-size: 1rem;
            color: $invalidText;
            &.success {
                color: $validText; } } }
    a {
        text-align: right;
        font-size: 1rem;
        text-decoration: none;
        color: $customerBrand;
        svg {
            color: $customerBrand; } }
    &.danger,
    &.warning {
        background-color: $backgroundError;
        color: $invalidText;
        border: none;
        border-radius: 5px;
        .noSwitch {
            display: grid;
            grid-template-columns: 30px 1fr;
            align-items: center;
            h4 {
                margin: 0; } }
        h3 {
            margin-bottom: 0; }
        a {
            text-align: right;
            font-size: 1rem;
            text-decoration: none;
            color: $invalidText;
            svg {
                color: $invalidText; } } }
    &.warning {
        background-color: $warningBackground;
        grid-template-columns: 1fr 1.7fr 0.7fr;
        .noSwitch {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            svg {
                margin-right: 1rem;
                color: $warningText; }
            h4 {
                width: 100%;
                text-align: left; } }
        .address-wrapper {
            small {
                font-size: 1rem; } }
        h4,
        small {
            margin-bottom: 0;
            color: $warningText; }
        a {
            color: $warningText; } } }

@media screen and ( max-width: 1366px ) {
    .locationItem-grid {
        padding: 0.7rem 1.5rem;
        .address-wrapper {
            padding: 0;
            h3 {
                font-size: 0.75rem; }
            small {
                font-size: 0.875rem; } }
        a {
            font-size: 0.875rem; }
        &.danger,
        &.warning {
            h3 {
                margin-bottom: 0; }
            a {
                font-size: 0.875rem; } }
        &.warning {
            .address-wrapper {
                small {
                    font-size: 0.8rem; } } } } }

@media screen and ( max-width: 1024px ) {
    .locationItem-grid {
        padding: 0.7rem 1rem;
        .address-wrapper {
            padding: 0;
            h3 {
                font-size: 0.6rem; }
            small {
                font-size: 0.75rem; } }
        a {
            font-size: 0.75rem; }
        &.danger,
        &.warning {
            h3 {
                margin-bottom: 0; }
            a {
                font-size: 0.75rem; } }
        &.warning {
            .address-wrapper {
                small {
                    font-size: 0.7rem; } } } } }

@media screen and ( max-width: 768px ) {
    .locationItem-grid {
        .address-wrapper {
            h3 {
                font-size: 0.5rem; }
            small {
                font-size: 0.65rem; } }
        a {
            font-size: 0.65rem; }
        &.danger,
        &.warning {
            a {
                font-size: 0.65rem; } }
        &.warning {
            .address-wrapper {
                small {
                    font-size: 0.55rem; } } } } }
